import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import SearchResult from "./pages/SeachResult";
import Enterprises from "./pages/Enterprises";
import Favorites from "./pages/Favorites";
import Comparatives from "./pages/Comparatives";
import PropertyRegistration from "./pages/PropertyRegistration";
import Company from "./pages/Company";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Error404 from './pages/Error404';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/detalhes/:modalidade/:codigo/:slug?" component={PropertyDetails} />
        <Route exact path="/:modalidade/categoria/:category" component={Enterprises} />
        <Route exact path="/:modalidade/:tipo/:endereco/:filtros?" component={SearchResult} />
        <Route exact path="/favoritos" component={Favorites} />
        <Route exact path="/comparativos" component={Comparatives} />
        <Route exact path="/cadastre-seu-imovel" component={PropertyRegistration} />
        <Route exact path="/sobre-nos" component={Company} />
        <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
        <Route exact path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  );
}