import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import SocialMedias from './SocialMedias';
import Banner from './Banner';
import EspecialRent from './EspecialRent';
import EspecialSale from './EspecialSale';
import QuickSearch from './QuickSearch';
import Benefits from './Benefits';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';

export default class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <Helmet title={`${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <SocialMedias />
                    <Banner />
                    <EspecialSale />
                    <EspecialRent />
                    <QuickSearch />
                    <Benefits />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}