import React from 'react';
import ImovelCard from '../../../components/Cards/ImovelCard';
import Pagination from "react-js-pagination";
import TitleLoader from '../../../components/TitleLoader';
import LoadingMask from "react-loadingmask";

import './styles.css';

export default function Content(props) {
    return (
        <>
        <div id="interprise">
            <div className="container">
                <h1>
                {props.loadingContent ? <TitleLoader /> :
                    (props.total > 0 ? 
                        `${props.total} imóve${props.total > 1 ? 'is':'l'} para a busca realizada` :
                        'Não foram encontrados imóveis para a busca realizada'
                    )
                }
                </h1>
                <LoadingMask loading={props.loadingContent} >
                    <div className="result-card">
                        {props.imoveis.map((imovel, index) => (
                            <div key={index} className="item">
                                <ImovelCard data={imovel} icon="heart" />
                            </div>
                        ))}
                    </div>
                </LoadingMask>                
            </div>
        </div>

        <div className="section-pagination">
      
                {props.total > props.limit ?             
                    <div className="content-pagination">
                        <nav>
                            <LoadingMask loading={props.loadingContent} >
                                <Pagination
                                    hideDisabled
                                    prevPageText='Anterior'
                                    nextPageText='Próximo'
                                    firstPageText='Início'
                                    lastPageText='Fim'
                                    activePage={props.page}
                                    itemsCountPerPage={props.limit}
                                    totalItemsCount={parseInt(props.total)}
                                    onChange={props.handlePageChange}/>
                            </LoadingMask>
                        </nav>
                    </div>
                : ''}
     
        </div>
        </>
    );
}