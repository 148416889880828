import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import Menu from '../../../components/Layout/Menu';

import Nav from './Nav';

import logomarca from '../../../assets/images/logomarcaHeader-white.png';

import './styles.css';

export default function Header() {

    const handleScroll = () => {
        const element  = document.querySelector('#header-home');

        window.addEventListener('scroll', function() {
            if (window.pageYOffset > 100) {
                element.classList.add('show-header-home');
            } else {
                element.classList.remove('show-header-home');
            }
        })
    }

    const openDrawer = () => {
        document.querySelector('#drawer-menu').classList.remove('no-drawer-menu');
        document.querySelector('#drawer-menu-overlay').classList.remove('no-overlay');
    }
       
    useEffect(() => { handleScroll() },[]);

    return (
        <header id="header-home">
            <div className="container">
                <div className="logomarca">
                    <Link to='/'><img src={logomarca} alt={process.env.REACT_APP_NAME}/></Link>
                </div>

                <Nav />

                <span className="btn-menu" onClick={openDrawer}>
                    <FiMenu size={18} />&nbsp;
                    Menu
                </span>
                <Menu />
            </div>
        </header>
    );
}