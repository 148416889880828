import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTop } from 'react-scroll';
import { FiChevronsUp } from 'react-icons/fi';

import logomarca from '../../../assets/images/logomarcaHeader-white.png';

import './styles.css';

export default function Benefits() {
    const now = new Date();

    const handleScroll = () => {
        const element  = document.querySelector('#scroll-top');

        window.addEventListener('scroll', function() {
            if (window.pageYOffset > 1000) {
                element.classList.add('show');
            } else {
                element.classList.remove('show');
            }
        })
    }
       
    useEffect(()=>{
        handleScroll();
    },[]);

    return (
        <footer>
            <div className="container">
                <div className="col-left">
                    <ul>
                        <li><h2><Link to="/">DMV Imóveis</Link></h2></li>
                        <li><Link to="/sobre-nos">Sobre nós</Link></li>
                        <li><Link to="/venda/categoria/construcao">Destaques de vendas</Link></li>
                        <li><Link to="/venda/apartamento/fortaleza">Imóveis à venda</Link></li>
                        <li><Link to="/alguel/apartamento/fortaleza">Imóveis para alugar</Link></li>
                        <li><Link to="/politica-de-privacidade">Política de Privacidade</Link></li>
                    </ul>

                    <ul>
                        <li><h2><Link to="/cadastre-seu-imovel">Cadastre seu imóvel</Link></h2></li>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Segunda via de boleto</Link></li>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Prestação de contas</Link></li>
                        <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank'>Extrato de IRRF</Link></li>
                    </ul>
                </div>

                <div className="col-right">                    
                    <ul className='content-logo'>                    
                        <li>
                            <Link to='/'><img src={logomarca} alt="DMV Imóveis"/></Link>                            
                        </li>
                        <li>
                           
                            <i className="fas fa-phone-alt"/> &nbsp;
                            {process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE}
                            <span>24 horas</span>
                        </li>
                        <li>
                            <Link to={{pathname: process.env.REACT_APP_WHATSAPP_SALE}} target="_blank">                               
                                <i className="fab fa-whatsapp"/> &nbsp;
                                {process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE_SALE}
                                <span>Venda</span>
                            </Link>
                        </li>                        
                        <li>
                            <Link to={{pathname: process.env.REACT_APP_WHATSAPP_RENT}} target="_blank">                                
                                <i className="fab fa-whatsapp"/> &nbsp;
                                {process.env.REACT_APP_DDD} {process.env.REACT_APP_PHONE_RENT}
                                <span>Locação</span>
                            </Link>
                        </li>                        
                        <li>
                            <i className="far fa-envelope"/> &nbsp;
                            {process.env.REACT_APP_EMAIL}
                        </li>
                    </ul>

                    <ul>
                        <li><h2>Horário de funcionamento</h2></li>
                        <li>Segunda à sexta-feira de <span>8h</span> às <span>18h</span></li>
                        <li>Sábados de <span>8h</span> às <span>12h</span></li>
                        <li className="footer-medias">                
                            <Link to={{pathname: process.env.REACT_APP_FACEBOOK}} target="_blank" className='first-icon'>
                                <i className="fab fa-facebook-f"/>
                            </Link>
                            <Link to={{pathname: process.env.REACT_APP_INSTAGRAM}} target="_blank">
                            <i className="fab fa-instagram"/>
                            </Link>
                            <Link to={{pathname: process.env.REACT_APP_YOUTUBE}} target="_blank" className='last-icon'>
                            <i className="fab fa-youtube"/>
                            </Link>                    
                        </li>
                    </ul>
                    <ul>
                        <li><h2>Nosso endereço</h2></li>
                        <li>{process.env.REACT_APP_ADDRESS}</li>
                        <li>{process.env.REACT_APP_ADDRESS_CITY}</li>
                        <li>CRECI {process.env.REACT_APP_CRECI}</li>
                    </ul>
                </div>
            </div>

            <div className="container" style={{justifyContent:'center'}}>
                <div className="copy-right">
                    <p>&copy; {now.getFullYear()} {process.env.REACT_APP_NAME} - Todos os direitos reservados</p>                    
                </div>
            </div>

            <ScrollTop 
                to="root" 
                smooth={true} 
                duration={800}
            >
                <FiChevronsUp id="scroll-top"/>
            </ScrollTop>            
        </footer>
    );
}