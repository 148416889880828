export default class BaseServices {
  static _getRequestInfo(method = 'GET', body = '') {
    let requestInfo = {
      method : method,
      mode: 'cors',
      headers : new Headers({
        'Content-Type' : 'application/json',
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFwaSBkbXYgaW1vdmVpcyIsImlhdCI6MTUxNjIzOTAyMn0.yc7GzykL7A1cY7cKK7TePxoXYo9xJFwei5sFagDTU_w',
        'Cache-Control': 'no-cache'
      })
    };

    if (body !== '') {
      requestInfo.body = body;
    }

    return requestInfo;
  };
}