import React from 'react';

import './styles.css';

export default function Topo() {
    return (
        <div id="company-topo">
            <div className="container">
                <div className="content">
                    <h1><strong>DMV</strong> - Consultoria Imobiliária e Jurídica </h1>
                    <p>
                        Somos uma empresa voltada para atender as mais modernas 
                        necessidades do mercado imobiliário cearense a nível nacional 
                        e internacional. Necessidade esta, fator primordial para suprir a 
                        imensa demanda de investimentos nacionais e estrangeiros em todo o Estado.
                    </p>
                </div>                
            </div>            
        </div>
    );
}