import React from 'react';
import Headline from '../../../components/Headeline';

import securety from '../../../assets/images/icons/securety.svg';
import auction from '../../../assets/images/icons/auction.svg';

import './styles.css';

export default function Options() {
    return (
        <section id="options-container">
            <Headline 
                title="O que está procurando?" 
                text="Está procurando um imóvel ou quer anunciar o seu com a gente? 
                A DMV atua no mercado da capital cearense, sempre acompanhando as 
                movimentações e negociações, para lhe ajudar"
            />
            <div className="container">
                <article>
                    <img src={securety} alt="Segurança e transparência"/>
                    <h2>Segurança e transparência</h2>
                    <p>
                        Administramos seu imóvel com segurança e transparência 
                        garantindo assim tranquilidade no recebimento da receita 
                        gerada pelo seu imóvel
                    </p>
                </article>
                <article>
                    <img src={auction} alt="Acompanhamento jurídico"/>
                    <h2>Acompanhamento jurídico</h2>
                    <p>
                        Dispomos de acompanhamento jurídico qualificado para a 
                        obtenção de VISTO PERMANENTE, abertura de empresa e toda 
                        atividade necessária ao suporte do investidor estrangeiro
                    </p>
                </article>
            </div>               
        </section>
    );
}