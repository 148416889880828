const initialState = {
    modalidade: 'venda',
    endereco: 'Fortaleza',
    tipo: 'apartamento',
    filters: {            
        quartos:'',
        suites:'',
        banheiros:'',
        vagas:'',
        valorInicial:'',
        valorFinal:'',
        areaInicial:'',
        areaFinal:''
    }
}

export default initialState;