import React from 'react';
import Headline from '../../../components/Headeline';
import ImovelCard from '../../../components/Cards/ImovelCard';
import Favorites from '../../../helpers/Favorites';

import './styles.css';

export default function Content() {
    const _favorites = new Favorites();
     
    return (
        <div id="favorites">            
            <Headline 
                title={ _favorites.getQtdFavorites() > 0 ? 'Meus imóveis favoritos' : 'Você ainda não favoritou imóveis'}
                text=""
            />

            <div className="container">
            { _favorites.getQtdFavorites() > 0 ?
                _favorites.getFavorites().map((imovel, index) => (               
                    <ImovelCard  key={index} data={imovel} icon='trash'/>        
                  )) 
                :
                <div className="no-favorites">                      
                    <h2>Como adicionar um imóvel aos meus favoritos?</h2>                   
                    <p>Na página de busca ou na página do imóvel, clique no <strong>botão <i className="far fa-heart" /></strong>&nbsp;
                    para adicionar aos favoritos.</p>
                </div>
            } 
            </div>
        </div>  
    );   
}