import React from "react"
import ContentLoader from "react-content-loader"

const TitleLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={136}
    height={20}
    backgroundColor="#ededed"
    foregroundColor="#fafafa"
    {...props}
  >
    <circle cx="26" cy="9" r="7" /> 
    <circle cx="66" cy="9" r="7" /> 
    <circle cx="107" cy="9" r="7" />
  </ContentLoader>
)

export default TitleLoader