import React, { Component, Fragment } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen';

import CardImovel from '../../../../components/Cards/ImovelCard';

import marker from '../../../../assets/images/icons/marker.svg';

import './styles.css';

const markerIcon = Leaflet.icon({
    iconUrl: marker,
    iconSize:[40, 40],
    iconAnchor:[20, 40],
    popupAnchor: [0, -30]
});

export default class MapResult extends Component {
    state = {
        latitude: -3.7477276400242556,
        longitude: -38.516791500102315
    }

    componentWillReceiveProps(nextProps){
      
        if (nextProps.list[0]) {
            this.setState({
                latitude: nextProps.list[0].latitude,
                longitude: nextProps.list[0].longitude
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Map 
                    center={[ this.state.latitude,  this.state.longitude]}
                    zoom={13}
                    scrollWheelZoom={true}
                    style={{ width: '100%', height: '100%' }}
                    fullscreenControl={true}
                >
                    <TileLayer
                        url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                    />            

                    {this.props.list.map((imovel, index) => (
                        <Marker
                            key={index}
                            position={[imovel.latitude, imovel.longitude ]}
                            icon={markerIcon}
                            >
                            <Popup maxWidth={230} className="popup-map-card-imovel">

                                <CardImovel data={imovel}/>

                            </Popup>
                        </Marker>
                    ))}                    
                </Map>
            </Fragment>
        );
    }
}