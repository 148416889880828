import React from 'react';
import FormRegistration from '../FormRegistration';

import './styles.css';

export default function Topo() {
    return (
        <div id="property-registration">
            <div className="container">
                <div className="content">
                    <h1>Anuncie seu imóvel aqui na <strong>DMV Imóveis</strong></h1>
                    <p>
                        Somos uma empresa voltada para atender as mais modernas 
                        necessidades do mercado imobiliário cearense a nível nacional 
                        e internacional
                    </p>
                </div>
                <div className="content-form">
                    <FormRegistration />
                </div>
            </div>
        </div>
    );
}