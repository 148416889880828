import React from 'react';
import { Link } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import Favorites from './../../../../helpers/Favorites';


import './styles.css';

export default function Nav() {
    const pathname = window.location.pathname;

    const _favorites = new Favorites();

    return (
        <nav id='nav-header'>
            <ul className="modality">
                <li>
                    <Link to='/venda/apartamento/fortaleza' className={pathname === '/venda' ? 'active': '' }>
                        Venda
                    </Link>
                </li>
                <li>
                    <Link to='/aluguel/apartamento/fortaleza' className={pathname === '/aluguel' ? 'active': '' }>
                        Aluguel
                    </Link>
                </li>
            </ul>
            <ul>
                <li>
                    <Link to='/favoritos'>
                        <i className="far fa-heart"/>
                        <span id='totalizadorFavorites' className="fav-count">{_favorites.getQtdFavorites()}</span>
                        Favoritos
                    </Link>
                </li>
                <li>
                    <Link to='/comparativos'>
                        <i className="fa fa-clone"/>
                        Comparativos
                    </Link>
                </li>
                <li>
                    <Link to={{pathname: process.env.REACT_APP_LOGIN}} target='_blank' >
                        <FiUser size={18} />
                        Login
                    </Link>
                </li>               
            </ul>
        </nav>
    );
}