import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import './styles.css';

const Breadcrumbs = (props) => {
    return (
        <div className="breadcrumb-company">
            <div className="container">
                <Breadcrumb>
                    <BreadcrumbItem><Link to='/'>DMV Imóveis</Link></BreadcrumbItem>                    
                    <BreadcrumbItem active>Sobre nós</BreadcrumbItem>
                </Breadcrumb>
            </div>
        </div>
    );
};

export default Breadcrumbs;