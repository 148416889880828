import React, { Fragment, Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Topo from './Topo';
import Footer from '../../components/Layout/Footer';
import WhatsApp from '../../components/Layout/Contato/Whatsapp';
import { pdfjs } from 'react-pdf';

export default class PrivacyPolicy extends Component {
    componentDidMount() {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }

    render() {
        return (
            <Fragment>
                <Helmet title={`Política de Privacidade | ${process.env.REACT_APP_NAME}`} />
                <Header />
                <main>
                    <Topo />
                </main>
                <Footer />
                <WhatsApp />
            </Fragment>
        );
    }
}