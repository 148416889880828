import React from 'react';
import FormSearch from '../FormSearch';
import FormCode from '../FormCode';

import videoHome from '../../../assets/videos/home1280x720.mp4';

import './styles.css';

export default function Banner() {
    const [formLocation, setFormLocation] = React.useState(true);

    const toggleForm = () => {
        setFormLocation(!formLocation);
    }

    return (
        <section id='home-banner'>
            {window.innerWidth > 767 ?
            <video autoPlay loop muted>
                <source src={videoHome} type="video/mp4"/>
            </video>
            :''}

            <div className="content">
                <h2>Seja bem-vindo à</h2>
                <h1>DMV Imóveis</h1>
                <p>O imóvel que você procura a gente tem!</p>

                {formLocation ? <FormSearch /> : <FormCode />}

                <button type="button" onClick={toggleForm}>
                    {formLocation ? 'Busca pelo código' : 'Busca pela localização'}
                </button>     
            </div>
        </section>
    )
}