import React from 'react';
import { Input } from "reactstrap";

import './styles.css';

export default function Topo(props) {
    return (
        <div id="enterprises-topo">
            <div className="container">
                <div className="content">
                    <Input
                        style={{width:'205px'}}
                        type="select"
                        name="category"
                        onChange={(event)=>props.handleOnSelect(event)}
                        value={props.category} >

                        {props.listCategory.map((item, index) => (
                            <option key={index} value={item.value}>{item.name}</option>
                        ))}
                    </Input>

                    <Input
                        type="select"
                        name="order"
                        onChange={(event)=>props.handleOnSelect(event)}
                        value={props.order} >
                            <option value="0">Ordenar por</option>                   
                            <option value="menor-preco">Menor preço</option>
                            <option value="maior-preco">Maior preço</option>
                            <option value="menor-area">Menor área</option>
                            <option value="maior-area">Maior área</option>
                            <option value="destaques">Destaques</option>
                    </Input>
                </div>
            </div>
        </div>
    );
}