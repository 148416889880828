const listPopularProperties = [
    {
        label: 'Casa à venda no Meireles',
        to: '/venda/casa/meireles'
    },
    {
        label:  'Salas para alugar',
        to: '/aluguel/sala/fortaleza'
    },
    {
        label : 'Casa para vender',
        to : '/venda/casa/fortaleza'
    },
    {
        label : 'Casa à venda na Aldeota',
        to : '/venda/casa/aldeota'
    },    
    {
        label : 'Salas à venda',
        to : '/venda/sala/fortaleza'
    },
    {
        label : 'Salas para alugar',
        to : '/aluguel/sala/fortaleza'
    },
    {
        label : 'Apartamentos à venda',
        to : '/venda/casa/fortaleza'
    },
    {
        label : 'Salas para alugar no Benfica',
        to : '/aluguel/sala/benfica'
    },
    {
        label : 'Apartamento à venda na Aldeota',
        to : '/venda/apartamento/aldeota'
    },
    {
        label : 'Salas para alugar no Montese',
        to : '/aluguel/sala/montese'
    },
    {
        label : 'Loja para alugar Centro',
        to : '/aluguel/loja/centro'
    },
    {
        label : 'Salas à venda',
        to : '/venda/sala/fortaleza'
    }
];

export default listPopularProperties;