import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import FormContact from '../FormContact';
import Share from '../Share';
import SimilarProperty from '../SimilarProperty';
import Favorites from '../../../../helpers/Favorites';
import Comparatives from '../../../../helpers/Comparatives';

import './styles.css';

export default function Contact(props) {
    const _favorites = new Favorites();
    const _comparatives = new Comparatives();

    const [activeFavorites, setActiveFavorites] = useState( _favorites.getIcons(props.imovel.id, 'heart') === 'fas fa-heart' && true);
    const [activeComparatives, setActiveComparatives] = useState( _comparatives.getIcons(props.imovel.id) === 'fas fa-clone' && true);

    const handleActiveFavorites = () => {
        _favorites.update(props.imovel);
        setActiveFavorites(!activeFavorites);
    };

    const handleActiveComparatives = () => {
        _comparatives.update(props.imovel);
        setActiveComparatives(!activeComparatives);
    };
	
	const whatsapp = props.imovel.modalidade === 'Venda' ? process.env.REACT_APP_PHONE_SALE : process.env.REACT_APP_PHONE_RENT;
	const whatsappLink = props.imovel.modalidade === 'Venda' ? process.env.REACT_APP_WHATSAPP_SALE : process.env.REACT_APP_WHATSAPP_RENT;

    return (
        <Fragment>
            <div className="box box-buttons">
                <button  
                    onClick={handleActiveFavorites} 
                    className={`${activeFavorites && 'active'}`} >
                    <i 
                        id={`icon_${props.imovel.id}`}                       
                        className={_favorites.getIcons(props.imovel.id, 'heart')}                    
                    />&nbsp;
                    Favoritos
                </button>
                <button  
                    onClick={handleActiveComparatives} 
                    className={`${activeComparatives && 'active'}`} >
                    <i 
                        id={`icon_${props.imovel.id}`}                       
                        className={_comparatives.getIcons(props.imovel.id)}                    
                    />&nbsp;
                    Comparativos
                </button>            
            </div>

            <div className="box box-form">
                <div className="box-whatsapp">
                    <i className="fab fa-whatsapp"/>
                    <h2>
                        Atendimento rápido
							<Link to={{pathname: whatsappLink}} target="_blank">                    
								{process.env.REACT_APP_DDD} {whatsapp}
							</Link>
                    </h2>
                </div>

                <FormContact 
                    modalidade={props.imovel.modalidade}
                    titulo={props.imovel.tituloDetalhes}
                    codigo={props.imovel.codigo}
                    bairro={props.imovel.bairro}
                    cidade={props.imovel.cidade}
                    estado={props.imovel.estado}
                />
            </div>

            <Share /> 

            <SimilarProperty data={props.imovelProximos} handleLoadImovel={props.handleLoadImovel}/>            
        </Fragment>
    );
}