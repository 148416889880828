import React from 'react';
import { FiMapPin } from 'react-icons/fi';

import './styles.css';

export default function Contact(props) {
    return (
        <div id="similar-property">
            <h2>Imóveis semelhantes no mesmo bairro</h2>

            <div className="block-card-similar">
                {props.data.map((card, index) => (
                    <div className="card-similar" key={index}>
                        <img src={card.foto} alt={card.titulo}/>
                        <span>
                            <h2 onClick={()=>props.handleLoadImovel(card.id, card.slug)}> {card.titulo} </h2>
                            <address><FiMapPin/> {card.bairro}, {card.cidade} - {card.estado}</address>
                            <h3>R$ {card.valor.replace(',00', '')}</h3>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}