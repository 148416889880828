import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import utils from '../../../helpers/utils';

import './styles.css';

const Breadcrumbs = ({params}) => {
    return (
        <div className="breadcrumb-content">
            <div className="container">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to='/'>{process.env.REACT_APP_NAME}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to={`/${params.modalidade}/${params.tipo}/${params.endereco}`}>
                            {params.modalidade === 'aluguel' ? 'Imóveis para alugar':'Imóveis à venda'}
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {utils.capitalize(params.tipo)}
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
        </div>
    );
};

export default Breadcrumbs;