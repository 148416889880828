import React, { useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import ContentHeadline from '../../../components/ContentHeadline';
import EspecialCard from '../../../components/Cards/EspecialCard';
import TitleLoader from '../../../components/TitleLoader';

import api from './../../../services/api';

import './styles.css';

export default function EspecialRent() {
    const [rent, setRent] = useState([]);

    const options = {
        margin: 10,
        responsive: {0: {items: 1}, 768: {items: 2}, 990: {items: 3}}
    };

    const carousel = useRef(null);

    const getEspecialRents =  async() => {     
        await api.get(`/aluguel/especialRents`).then(
            response => { setRent(response.data.imoveis); }
        );
    }

    useEffect(() => {
        getEspecialRents();
    },[]);

    return (
        <section id='especial-rent'>
            <ContentHeadline 
                title="Nossos destaque de aluguel"
                text="Veja mais algumas novidades da DMV e escolha o seu imóvel" 
            />

            <div className="carousel">            
                <div className="container">

                {rent.length > 0 ?
                    <>
                        <OwlCarousel ref={carousel} options={options}>
                            {rent.map((imovel, index) => (
                                <div key={index} className="item">
                                    <EspecialCard data={imovel} icon="heart"/>
                                </div>
                            ))}
                        </OwlCarousel>                    
                            
                        <div className="carousel-nav">
                            <span onClick={() => carousel.current.prev()}><i className="fas fa-angle-double-left"/></span>
                            <span onClick={() => carousel.current.next()}><i className="fas fa-angle-double-right"/></span>
                        </div>
                    </>
                :
                    <span className="loader"><TitleLoader /></span>   
                }
                </div>
            </div>
        </section>
    );
}