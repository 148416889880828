import React from 'react';
import { Link } from 'react-router-dom';
import EspecialLabel from '../../EspecialLabel';
import utils from '../../../helpers/utils';
import Favorites from '../../../helpers/Favorites';
import Comparatives from '../../../helpers/Comparatives';

import './styles.css';

export default function EspecialCard({data, icon}) {
    const _favorites = new Favorites();
    const _comparativesProperties = new Comparatives();

    const getSlug = (tipo, quartos, suites, bairro, cidade, estado) => {
        let dormitorios_total = quartos + suites;
        let slug = '';

        if (dormitorios_total === 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios_total > 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return utils.transliterate(slug);
    }

    return (
        <div id={`card-content-${data.id}`} className="especial-card">
            <Link to={`/detalhes/${data.modalidade}/${data.id}/${getSlug(data.tipo, data.quartos, data.suites, data.bairro, data.cidade, data.estado)}`}></Link>
            
            {data.label_destaque !== '' ?
                <EspecialLabel 
                    label={data.label_destaque}
                    color={data.modalidade === 'venda' && data.label_destaque ? 'orange':'green'}
                />
            : ''}

            <div className="box-buttons">
                <i 
                    id={`icon_${data.id}`} 
                    className={_favorites.getIcons(data.id, icon)} 
                    onClick={() => _favorites.update(data)}
                />

                <i 
                    id={`icon-comparatives-${data.id}`} 
                    className={_comparativesProperties.getIcons(data.id)}
                    onClick={() => _comparativesProperties.update(data)}
                />
            </div>

            <div className="box-image">
                <img src={data.foto} alt={data.titulo}/>
            </div>

            <div className="wrapper">
                <h2>{data.titulo}</h2>
                <address>{data.bairro}, {data.cidade} - {data.estado}</address>

                <div className="values">
                    <span>R$ {data.valor.replace(',00', '')}</span>
                    <span>{data.area === 0 ? data.area_total.replace(',00','') : data.area.replace(',00','')}m²</span>
                </div>
                <ul>
                    <li>
                        {data.suites > 1 && data.suites != data.quartos && data.quartos != 0 &&
                            <li>
                                {data.quartos} dormitórios, sendo {data.suites} suítes
                            </li>
                        }
                        {data.suites == 1 && data.suites != data.quartos && data.quartos != 0 &&
                            <li>
                                {data.quartos} dormitórios, sendo {data.suites} suíte
                            </li>
                        }
                        {data.suites == data.quartos && data.quartos != 0 &&
                            <li>
                                {data.suites} suítes
                            </li>
                        }
                        {data.quartos == 0 && data.suites > 0 &&
                            <li>
                                {data.suites} suítes
                            </li>
                        }
                        {data.quartos == 0 && data.suites == 0 &&
                            <li>
                                {data.suites} dormitórios
                            </li>
                        }
                    </li>

                    <li>{data.banheiros} banheiros</li>
                    <li>{data.vagas} vagas</li>
                </ul>
            </div>                                
        </div>
    );
}