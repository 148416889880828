import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import './styles.css';

export default function Topo() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
      }
    
      function previousPage() {
        changePage(-1);
      }
    
      function nextPage() {
        changePage(1);
        window.scrollTo(0, 0);
      }

    return (
        <div id="privacy-topo">
            <div className="container">
                <div className="content" style={{margin: '0 auto', textAlign: 'center'}}>
                    <h1><strong>DMV</strong> - Política de Privacidade </h1>
                    <div>
                        <Document file="https://dmvimoveis.com.br/docs/politica-de-privacidade.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                             Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                            </p>
                            <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                            >
                            Anterior
                            </button>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                            Próxima
                            </button>
                    </div>
                </div>                
            </div>            
        </div>
    );
}